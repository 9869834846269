$brand-primary: #6F4898
$brand-primary-notable: lighten($brand-primary, 5%)
$brand-accent: #FFA23C
$brand-accent-light: #FFE5C9
$brand-error: #ff4d4f
$brand-light: lighten($brand-primary, 32%)
$brand-lighter:  lighten($brand-light, 3%)
$brand-notable:  #3E6FA7

$headings-line-height:    1 !important

$gray-base:              #000 !default
$gray-darker:            lighten($gray-base, 13.5%) !default // #222
$gray-dark-med:           lighten($gray-base, 17%) !default
$gray-dark:              lighten($gray-base, 20%) !default   // #333
$gray:                   lighten($gray-base, 33.5%) !default // #555
$gray-light:             lighten($gray-base, 46.7%) !default // #777
$gray-light-med:           lighten($gray-base, 70%) !default
$gray-light-more:           lighten($gray-base, 80%) !default
$gray-light-high:           lighten($gray-base, 88%) !default
$gray-lighter:           lighten($gray-base, 93.5%) !default // #eee
$gray-lightest:           lighten($gray-base, 97%) !default

$navbar-height: 46px !default
$nav-link-padding: 7px 12px !default

$component-active-bg:  $gray-lighter !default
$component-active-color: $gray !default


$border-radius-base:        4px !default
$border-radius-large:       6px !default
$border-radius-small:       3px !default

$link-hover-decoration: none

$break-sm: 640px
$break-md: 920px
$break-la: 1200px
$break-exla: 1900px

//Panels
$panel-info-heading-bg: $brand-light !default
$panel-info-border: none !default
$panel-info-text: #000000 !default

//Tooltips
$tooltip-bg: #FFF
$tooltip-color: $gray

//Modals
$modal-lg: 90vw

//Inputs
// $input-border: #FFF
// $input-bg: $brand-lighter
$input-border-radius: 5px !important
$input-border-focus: darken($brand-lighter, 10%)
// $input-height-large: 40px
$input-border-focus: $brand-primary

//Input Addon
$input-group-addon-bg: #FFF
$form-group-margin-bottom: 0

.input-group-addon:hover
  h5
    color: $brand-primary !important


//Carousels
$carousel-control-width: 10%
$carousel-control-color: $gray-lightest
