@import variables
@import Fonts

@font-face
  font-family: marketingScript
  src: url('../fonts/font.woff')

.login-bg
  background: linear-gradient(135deg, hsla(269, 36%, 44%, 1) 0%, hsla(269, 36%, 44%, 1) 21%, hsla(285, 25%, 43%, 1) 45%, hsla(14, 26%, 51%, 1) 74%, hsla(38, 68%, 50%, 1) 91%, hsla(43, 100%, 50%, 1) 100%)

.kamero-brand-text
  font-family: marketingScript, $font-family
  font-size: 48px
  margin-bottom: -5px
  background-color: transparent
  user-select: none
  color: #FFF

.login-card
  user-select: none
  border-radius: 7px !important
  margin-bottom: 20px
  min-width: 350px
  .ant-card-head
    border-top-left-radius: 7px !important
    border-top-right-radius: 7px !important
    background-color: $brand-light
    padding: 10px
    .ant-card-head-title
      padding: 0px
      h3
        margin: 2px
  .ant-card-body
    padding: 10px


.ant-card-body > p
  margin-bottom: 20px

.login-sharing-text
  font-family: marketingScript, $font-family
  color: #FFF
  font-size: 17px
  font-weight: 200
  margin-bottom: 24px

.loginKameroIcon
  position: absolute
  left: 20px
  top: 20px
  width: 50px
  height: 50px

// New styles for wider input fields
.login-form-space
  width: 90%

.login-input
  width: 100%
  min-width: 280px

// Media query for larger screens
@media (min-width: 768px)
  .login-input
    width: 320px