@import variables

$primary-purple: #6F4898
$secondary-yellow: #FFB700
$primary-light: rgba(111, 72, 152, 0.1)
$hover-purple: darken($primary-purple, 10%)

.uploadContainerWithDragDrop
  outline: 2px dashed rgba($primary-purple, 0.3)
  outline-offset: -2px
  background: white
  border-radius: 12px
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05)
  transition: all 0.3s ease
  display: flex
  flex-flow: column nowrap
  flex: 1 0 auto
  justify-content: center
  align-items: center
  text-align: center
  min-height: 130px
  max-height: 300px
  margin-bottom: 20px
  padding: 16px
  position: relative
  overflow: hidden

  &:hover:not(.no-drop)
    outline-color: $primary-purple
    background: $primary-light

  &.no-drop
    opacity: 0.7
    cursor: not-allowed
    background: #f5f5f5

  .lucide-cloud-upload
    color: $primary-purple
    margin-bottom: 12px
    transition: transform 0.3s ease
    
    &:hover
      transform: scale(1.1)

  h3
    color: $primary-purple
    font-weight: 600
    margin-bottom: 12px
    font-size: 16px
    
  h5
    color: rgba(0, 0, 0, 0.45)
    margin: 8px 0
    font-size: 14px

.ant-alert
  margin: 8px 0
  border-radius: 6px
  
  &.ant-alert-warning
    background: rgba($secondary-yellow, 0.1)
    border-color: $secondary-yellow
    
    .ant-alert-message
      color: darken($secondary-yellow, 15%)
      font-weight: 500
      font-size: 14px
    
    .ant-alert-description
      font-size: 13px

.upload-container-watermark-option
  position: absolute
  top: 8px
  right: 8px
  margin: 0
  z-index: 1

  @media (max-width: $break-md)
    position: relative
    top: 0
    right: 0
    align-self: center
    margin-bottom: 8px

  .ant-switch
    background-color: rgba($primary-purple, 0.5)
    
    &.ant-switch-checked
      background-color: $primary-purple

.switchHolder
  display: flex
  justify-content: flex-end
  align-items: center
  padding: 4px 8px
  
  h4
    margin-right: 8px
    user-select: none
    color: rgba(0, 0, 0, 0.85)
    font-weight: 500
    font-size: 14px

.upload-limits
  margin: 8px 0
  
  .ant-typography
    color: rgba(0, 0, 0, 0.65)
    font-size: 14px
    
    strong
      color: $primary-purple
      font-weight: 600

.btn-gray
  background: $primary-purple
  color: white
  border: none
  height: 36px
  border-radius: 6px
  font-weight: 500
  padding: 0 16px
  font-size: 14px
  
  &:hover:not(:disabled)
    background: $hover-purple
  
  &:disabled
    background: rgba($primary-purple, 0.5)
    cursor: not-allowed

.mobile-upload-container
  width: 100%
  max-width: 320px
  margin: 0 auto
  
  .mobile-upload-button
    background: rgba($primary-purple, 0.05)
    border-radius: 6px
    padding: 12px
    display: flex
    align-items: center
    justify-content: center
    
    &:hover:not(.disabled)
      background: rgba($primary-purple, 0.1)
    
    &.disabled
      opacity: 0.7
      cursor: not-allowed
    
    h3
      color: $primary-purple
      margin: 0
      font-size: 16px
      font-weight: 600

.upload-progress
  width: 100%
  max-width: 400px
  margin-top: 12px
  display: flex
  gap: 20px
  align-items: center

  .ant-progress
    flex: 1
    
    .ant-progress-inner
      background-color: rgba($primary-purple, 0.1)
    
    .ant-progress-bg
      background-color: $primary-purple
    
    .ant-progress-text
      color: $primary-purple
      font-weight: bold
      font-size: 14px

.upload-progress-cancel-button
  height: 32px
  padding: 0 16px
  border-radius: 4px
  color: $primary-purple
  border-color: $primary-purple
  font-size: 14px
  white-space: nowrap
  flex-shrink: 0
  margin-left: 32px
  
  &:hover
    color: white
    background: $hover-purple
    border-color: $hover-purple

p
  font-size: 13px
  color: rgba(0, 0, 0, 0.45)
  margin: 8px 0

.or-divider
  font-size: 13px
  color: rgba(0, 0, 0, 0.45)
  margin: 8px 0
  display: inline-block
