.left-menu
  border-right: none !important
  background-color: transparent !important
  .ant-menu-item
    user-select: none
    padding-left: 5px !important
    padding-right: 5px !important
    border-radius: 7px !important
    margin-top: 4px
    margin-bottom: 2px !important
    font-size: 15px !important
    background-color: $gray-lightest
    span
      font-size: 15px
      font-weight: 400
      color: $font-color-secondary
  .ant-menu-item-selected
    background-color: $brand-accent-light !important

    
.sort-by-menu-item
  margin: 0px !important

.tab-action-icon
  svg
    width: 18px
    height: 18px

.sortable-row-item
  .name-editdelete-tab-row
    user-select: none
    margin-top: 3px
    align-items: center
    height: 40px
    padding-left: 8px
    padding-right: 8px
    border-radius: 7px
    background-color: $gray-lightest
    cursor: pointer
    &:hover
      color: $font-color-primary
  .selected
    background-color: $brand-accent-light
