@import variables

.anticon.clickable:hover path
    fill: lighten($brand-primary, 8%) !important
    
.anchorDiv
    display: inline
    color: $brand-primary
    &:hover
      text-decoration: underline
      cursor: pointer

.permissionButton
    display: inline
    &:hover
      text-decoration: underline
      cursor: pointer

.slick-list .slick-track
    overflow: hidden

.slick-dots
  bottom: 0px !important

.slick-dots
    li
      width: 30px !important
      height: 10px !important
      button
        width: 30px !important
        height: 10px !important
        background-color: lighten($brand-primary, 8%) !important

.slick-dots
    li.slick-active
        button
          background-color: darken($brand-primary, 10%) !important


.carousel-img-wrap
  position: relative

.carousel-img
  max-height: 100%
  max-width: 100%
  margin: auto
  position: absolute
  top: 0
  left: 0 
  bottom: 0 
  right: 0


.carousel-arrow
  position: absolute
  top: 50%
  font-size: 32px
  cursor: pointer
  &:hover
    font-size: 33px

.carousel-left-arrow
  left: 0
  margin-left: 24px

.carousel-right-arrow
  right: 0
  margin-right: 24px
