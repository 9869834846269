.order-id-collapse
  .ant-collapse-header
    padding-top: 0px !important
    padding-bottom: 0px !important

  .ant-collapse-arrow
    padding-top: 5px !important

.orders-container
  display: flex
  flex-wrap: wrap
  justify-content: space-between

  .order-card
    width: 100%
    margin-bottom: 10px

    @media (min-width: 768px)
      width: 49%

    .order-details
      display: flex
      flex-wrap: wrap
      justify-content: space-between
      margin-top: 10px


      p
        margin-bottom: 5px
        width: 50%

        @media (min-width: 768px)
          width: auto
          margin-right: 10px

      button
        margin-top: 10px

        @media (min-width: 768px)
          margin-top: 0