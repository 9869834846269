@import variables

// $font-family: Montserrat, Arial
$font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"
// $font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"

//Montserrat
//normal - 400
//medium - 500
//Light - 300    

$font-color-primary: $brand-primary
$font-color-title: #555
$font-color-secondary: #666

body
    font-family: $font-family !important

.ant-menu-item
    font-size: 16px
    font-weight: 400

h1
    font-size: 32px
    font-weight: 400
    color: $font-color-title
    margin-top: 16px
    margin-bottom: 16px

h2
    font-size: 18px
    font-weight: 400
    margin-top: 8px
    margin-bottom: 8px
    color: $font-color-primary

h3
    font-size: 18px
    font-weight: 500
    margin-top: 8px
    margin-bottom: 8px
    color: $font-color-title

h4
    font-size: 16px
    font-weight: 400
    margin-top: 8px
    margin-bottom: 8px
    color: $font-color-primary

h5
    font-size: 16px
    font-weight: 400
    margin-top: 8px
    margin-bottom: 8px
    color: $font-color-title

h6
    font-size: 14px
    font-weight: 400
    margin-top: 8px
    margin-bottom: 8px
    color: $font-color-primary

p
    font-size: 14px
    font-weight: 400
    color: $font-color-secondary
    margin-top: 4px
    margin-bottom: 4px