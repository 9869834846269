@import variables
@import Layouts
@import navBar
@import Fonts
@import Comps
@import tabs
@import upload
@import Orders

html, body
  margin: 0
  padding: 0

.nav-plus-content
  min-height: 100vh !important

.with-nav-body
  margin-top: var(--top-margin, 46px)
  margin-left: 20px
  margin-right: 20px

.ant-popover
  max-width: 300px

.page-header > h1
  margin-top: 15px
  margin-bottom: 2px

.panel-body > p
  margin-bottom: 0px

.flexContainer
  display: flex
  justify-content: center

.inner
  align-self: center

.api-error-parent
  position: relative
  width: 100%

.api-error-parent .api-error
  position: absolute
  z-index: 999999999
  top: calc(100vh - 130px)
  right: 25%
  left: 25%