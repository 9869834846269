@import variables
@import Fonts

$navbar-height: 46px


$nav-link-padding: 0px 0px

.navbar
  border-radius: 0px
  height: 46px
  background-color: #6F4898
  

.ant-layout-header
  padding-left: 20px !important
  padding-right: 20px !important

.navIcon
  width: 89px
  height: 28px
  margin-bottom: 5px
  vertical-align: middle


.navIconMobile
  width: 89px
  height: 28px
  vertical-align: middle

.navItem
  user-select: none
  &:hover
    background-color: #FFB700 !important
    
.pageTitle
  flex: 1 100%

.pageTitleBar
  margin-top: 5px
  margin-bottom: 10px
  display: flex
  justify-content: space-between
  align-items: center
  box-sizing: border-box
  flex: 100%

.pageRightDetail
  height: 40px
  width: 180px
  flex: 1 auto
  align-self: center

.pageTitleBarcode
  margin-right: 8px
  flex: 1 auto
  align-self: center

.pageTitleEventCode
  flex: 1 auto

.pageBackButton
  margin: 5px
  margin-right: 15px
  box-sizing: border-box
  min-width: 20px
  min-height: 20px
  polygon
    fill: $font-color-title

.pageBackButton:hover polygon
  fill: $brand-primary

