.flexCenterPageContainer
  display: flex
  justify-content: center
  align-items: center
  height: 100vh

.flexCenterPageChild
  text-align: center

.flexCenterFullScreenContainer
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  height: 100vh
  width: 100vw
  position: fixed
  left: 0
  top: 0

//Single Row Horizontal
.SingleRowHorizontalContainer
  display: flex
  align-items: center

.SingleRowHorizontalChild
  align-self: center

.no-data-placeholder
  flex: 1 auto
  height: calc(100vh - 200px)
  display: flex
  flex-flow: column nowrap
  justify-content: center
  align-items: center
