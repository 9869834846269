.card
  background: #fff
  border-radius: 2px
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)
  transition: all 0.3s cubic-bezier(.25,.8,.25,1)
  cursor: pointer

.thumbnail
  width: 100%
  background-repeat: no-repeat
  background-position: center center
  background-size: cover

.event-thumbnail
  width: 100%
  height: 100%
  background-repeat: no-repeat
  background-position: center center
  background-size: cover
  border-top-left-radius: 4.5px
  border-top-right-radius: 4.5px

.card:hover
  box-shadow: 0 6px 18px rgba(0,0,0,0.25), 0 6px 12px rgba(0,0,0,0.22)

.fullscreen-carousel
  height: calc(100vh - 120px)
  
.fullscreen-img
  height: calc(100vh - 150px)

.carousel-control.left, .carousel-control.right
  background-image: none
